import React, { useEffect } from "react";
import Layout from "../components/Layout";
import axios from "axios";
import { Helmet } from "react-helmet";
import Favicon from "../images/logos/Gogreen-favicon.png"

const OffsetConfirmation = () => {
  if (typeof window === "undefined") {
    var params = new URLSearchParams({ f: "bar", l: "bar" });
  } else {
    var params = new URLSearchParams(window.location.search);
  }

  // Email Confirmation after successfull payment

  useEffect(() => {
    createPDF();
  }, []);

  const createPDF = async () => {
    await axios.post(
      "/.netlify/functions/offset-attachment-emails",
      {
        //https://ugecredits.com/.netlify/functions/function_here/offset-attachment-emails//

        //http://localhost:8888/.netlify/functions/offset-attachment-emails//
        email: params.get("e"),
        pdfName: params.get("f") + "-" + params.get("l"),
        name: params.get("f") + " " + params.get("l"),
        usage: params.get("energy"),
        price: params.get("pr"),
        recurring: params.get("r"),
        fee: Math.round([(params.get("pr") / 100) * 3] * 100) / 100,
        total: (
          Number(params.get("pr")) +
          Math.round([(params.get("pr") / 100) * 3] * 100) / 100
        ).toFixed(2),
      }
    );
  };

  // Certificate PDF
  const certificatePDF = async (e) => {
    var pdfWindow = window.open("", "_blank");
    pdfWindow.document.write("Loading PDF...");

    // let createPdfUrl = `http://localhost:8888/.netlify/functions/certificatePdf/`

    let createPdfUrl = `/.netlify/functions/certificatePdf`;

    switch (params.get("c")) {
      case "":
        var body = {
          name: params.get("f") + " " + params.get("l"),
          usage: params.get("energy"),
        };
        break;
      default:
        var body = {
          name: params.get("c"),
          usage: params.get("energy"),
        };
    }

    const pdfFile = await axios.post(createPdfUrl, body, {});

    var base64str = pdfFile.data;

    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64str.replace(/\s/g, ""));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    var blob = new Blob([view], { type: "application/pdf" });

    // opens pdf in new tab
    // var newWindow = window.open();
    var url = (window.URL || window.webkitURL).createObjectURL(blob);

    pdfWindow.location.href = url;

    e.preventDefault();
  };

  console.log(params.get("f") + " " + params.get("l"));
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Go Green Credits | Thank You</title>

        <meta
          name="description"
          content="Go Green can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green! "
        />
        <link
          rel="icon"
          href={Favicon}
        />
      </Helmet>
      <main class="relative mx-auto mt-14 max-w-7xl">
        <div class="flex justify-between mb-10 ">
          <div class="w-1/2">
            <p class="mt-4 text-4xl tracking-tight font-extrabold text-emerald-500 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
              Thank you for helping to offset carbon emissions!
            </p>
            <p class="mb-5 mt-3 text-base text-gray-600 sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
              Your decision to purchase carbon offset credits enables Callective
              Energy to eliminate potent greenhouse gases across the globe. From
              all of us at Callective Energy, Thank you!
            </p>
            <button
              onClick={certificatePDF}
              class="flex justify-start py-2 items-center hover:opacity-70 hover:underline "
            >
              <h3 class="font-medium text-orange-500 text-lg">
                Download Your Official Carbon Offset Certificate Now!
              </h3>

              <div class="px-4 font-semibold text-orange-500 flex justify-center items-center rounded focus:outline-none">
                <svg
                  class="w-12 h-12 fill-current"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M8 20h3v-5h2v5h3l-4 4-4-4zm11.479-12.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z" />
                </svg>
              </div>
            </button>
            <ul
              role="list"
              class="text-sm font-medium text-gray-500 divide-y divide-gray-200"
            >
              <li class="flex py-6 space-x-6 items-center">
                <svg
                  class="w-20 h-20 fill-green-500"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M23.322 19.318l-4.072 1.192-1.193-4.072 1.763.964c.737-1.305 1.18-2.798 1.18-4.402 0-3.681-2.224-6.849-5.396-8.242l.623-1.914c3.975 1.657 6.772 5.579 6.772 10.156 0 1.953-.521 3.779-1.416 5.367l1.739.951zm-11.323 2.682c-3.332 0-6.24-1.825-7.796-4.523l1.769-1.026-4.102-1.089-1.089 4.1 1.694-.982c1.899 3.295 5.447 5.52 9.524 5.52 2.202 0 4.248-.654 5.969-1.768l-1.24-1.588c-1.376.854-2.994 1.356-4.729 1.356zm-8.994-8.876l-.006-.124c0-4.625 3.51-8.433 8.004-8.932l-.004 1.932 3.005-2.996-2.995-3.004-.003 2.05c-5.61.503-10.007 5.21-10.007 10.95l.021.402 1.985-.278zm6.126-.564c-.251.862-.146 1.768.415 2.571.904-1.333 2.588-2.767 3.76-3.039-2.395 1.731-3.735 4.16-3.909 6.908h1.011c-.023-1.038.125-2.345.497-2.934.911.302 1.924.125 2.797-.613 1.724-1.463 1.077-5.14 4.112-5.564-4.07-2.224-7.943.124-8.683 2.671z" />
                </svg>
                <div class="flex-auto space-y-1">
                  {params.get("r") === "/yr" ? (
                    <h3 class="text-gray-900">Annual Carbon Offset</h3>
                  ) : (
                    <h3 class="text-gray-900">Monthly Carbon Offset</h3>
                  )}
                  <p>{params.get("energy")} Tons</p>
                </div>
                <span class="font-bold text-gray-600 text-lg">
                  ${params.get("cost")}
                </span>
              </li>
            </ul>
            <dl class="text-sm font-medium text-gray-500 space-y-6 border-t border-gray-200 pt-6">
              <div class="flex justify-between">
                <p>Subtotal</p>
                <span class="font-bold text-gray-600 text-lg">
                  ${params.get("cost")}
                </span>
              </div>

              <div class="flex justify-between">
                <p>Transaction Fee</p>
                <span class="font-bold text-gray-600 text-lg">
                  ${Number(params.get("cost") * 0.03).toFixed(2)}
                </span>
              </div>

              <div class="flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6">
                <p class="text-base">Total</p>
                <span class="font-bold text-emerald-600 text-lg">
                  ${params.get("cost")}{" "}
                  {params.get("r") === "/yr" ? (
                    <span class="font-medium text-sm">/yr</span>
                  ) : (
                    <span class="font-medium text-sm">/mo</span>
                  )}
                </span>
              </div>
            </dl>
            <dl class="mb-10 bg-orange-100 bg-opacity-50 p-8 rounded-lg  mt-8 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
              <div>
                <p class="font-medium text-gray-900">Customer Details</p>
                <dd class="mt-2">
                  <address class="not-italic">
                    <span class="block">
                      {" "}
                      {params.get("f") + " " + params.get("l")}
                    </span>
                    <span class="mt-1 block"> {params.get("e")}</span>
                    <span class="mt-1 block"> {params.get("p")}</span>
                  </address>
                </dd>
              </div>{" "}
              <div>
                <p class="font-medium text-gray-900">Billing Details</p>
                <dd class="mt-2">
                  <address class="not-italic">
                    <span class="block"> {params.get("a")}</span>
                    <span class="mt-1 block"> {params.get("ac")}</span>
                  </address>
                </dd>
              </div>{" "}
            </dl>{" "}
            <div class="mb-10 flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6">
              <p class="text-base">Share with all your friends!</p>
              <div class="flex space-x-6">
                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Facebook</span>
                  <svg
                    class="h-7 w-7"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>

                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Instagram</span>
                  <svg
                    class="h-7 w-7"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>

                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Twitter</span>
                  <svg
                    class="h-7 w-7"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>

                <a href="#" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Linkedin</span>
                  <svg
                    class="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="w-1/2 pl-14 ">
            <img
              src="https://tailwindui.com/img/ecommerce-images/confirmation-page-06-hero.jpg"
              alt="TODO"
              class="rounded-3xl h-full w-full object-center object-cover"
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default OffsetConfirmation;
